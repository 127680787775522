@import "src/assets/style";

.P-authorizationButton {
  min-width: 6.56vw;
  border-radius: 21vw;
  height: 2.1vw;
  padding: 0px 1vw;
  font-family: $Poppins-Medium;
  font-size: 1vw;
  line-height: 1.5vw;
  transition: all .3s ease-in-out;
  position: relative;
  white-space: nowrap;
  @include screen-portrait {
    min-width: 21.4vw;
    padding: 0px 3.3vw;
    font-size: 4vw;
    height: 8.65vw;
  }

  /*&.comingSoon {
    cursor: default;

    &:hover {
      color: transparent;

      &:before {
        max-height: 42px;
        color: $white;
      }
    }

    &:before {
      content: "Coming Soon";
      text-transform: uppercase;
      font-size: 12px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      max-height: 0;
      overflow: hidden;
      transition: all .3s ease-in-out;
    }
  }
*/
  &.secondary {
    background: none;
    border: .05vw solid $main-color;
    color: $white;

    &:hover {
      background: $main-color;
    }
  }

  &.primary {
    background: $main-color;
    color: $white;
    border: .05vw solid $main-color;

    &:hover {
      background: none;
    }
  }
}