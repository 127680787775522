@import "src/assets/style";

.P-meditation {
  display: flex;
  align-items: center;
  gap: 1vw;
  cursor: pointer;
  @include screen-portrait {
    flex-direction: row-reverse;
  }

  &:hover {
    /*.P-textAsBanner {
      &:before {
        color: #0000FF;
        animation: glitcBlue 1.7s ease-in-out forwards;
      }

      &:after {
        color: #FF0000;
        animation: glitcRed 1.7s ease-in-out forwards;

      }
    }*/

    .P-banner {
      &:before {
        animation: glitcRed 1.7s ease-in-out forwards;

      }

      &:after {
        animation: glitcBlue 2s ease-in-out forwards;

      }


    }
  }

  .P-banner {
    width: 11.5vw;
    overflow: hidden;
    aspect-ratio: 1;
    border-radius: 1vw;
    border: .1vw solid $Contrast_colors-dark;
    background-color: rgba(180, 41, 249, 0.23);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: relative;
    z-index: 1;
    height: auto;
    max-height: 100%;

    //for comment
    display: flex;
    align-items: center;
    justify-content: center;
    //________________________
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border-radius: 1vw;
      border: .1vw solid $Contrast_colors-dark;
      background-color: rgba(180, 41, 249, 0.23);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      z-index: -2;
      mask-image: radial-gradient(#000 99%, transparent 100%);

      mask-repeat: no-repeat;
      mask-position: left;
      @include screen-portrait {
        width: 40vw;
        border: .5vw solid $Contrast_colors-dark;
        border-radius: 8.6vw;
      }
    }


    &:before {
      filter: hue-rotate(90deg);

    }

    &:after {
      filter: hue-rotate(290deg);

    }

    @include screen-portrait {
      width: 40vw;
      border: .5vw solid $Contrast_colors-dark;
      border-radius: 8.6vw;

    }
  }

  .P-title {
    font-family: $Fofbb-Regular;
    font-size: 1.8vw;
    line-height: 2.5vw;
    letter-spacing: 1px;
    color: $white;
    white-space: nowrap;
    position: relative;
    text-shadow: 0.5vw 0.5vw 0.4vw #5A108F;
    @include screen-portrait {
      font-size: 6.3vw;
      line-height: 6vw;
    }
  }

}
