@import "src/assets/style";

.P-EachMeditationAdminPage {
  .P-days {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    align-items: stretch;
    gap: 2vw;
    margin-bottom: 4vw;
    padding: 5vw 0;
    //justify-content: center;

    @include screen-portrait {
      gap: 6vw;
    }

    .P-day {
      min-width: 10vw;
      height: 5vw;
      color: $white;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='26' ry='26' stroke='white' stroke-width='6' stroke-dasharray='15' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 26px;
      width: fit-content;
      //padding: 2vw;
      justify-content: center;
      align-items: center;
      display: flex;
      @include screen-portrait {
        height: 20vw;
        width: 36vw;
      }
    }
  }
}