@import "src/assets/style";


.P-futuSwitcher {
  display: flex;
  align-items: center;

  .P-switcherContainer {
    height: 1.5vw;
    aspect-ratio: 2;
    display: flex;
    border: .2vw solid $secondary-color-purple-light;
    border-radius: 1vw;
    position: relative;
    transition: .3s all ease-in-out;
    cursor: pointer;

    .P-switcher {
      height: 100%;
      aspect-ratio: 1;
      background-color: $Contrast_colors-light;
      border-radius: 50%;
      border: .2vw solid $Contrast_colors-light;
      left: 0;
      right: 1.5vw;
      position: absolute;
      left: 0;
      transition: .3s all ease-in-out;

    }

    &.checked {
      background-color: $secondary-color-purple-light;

      .P-switcher {

        //background-color: $secondary-color-purple-light;
        left: 1.5vw;
        right: 0;
      }
    }
  }
}