@import "src/assets/style";



.P-futuButton {
  align-items: center;
  aspect-ratio: 3.5;

  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 100vw;
  box-shadow: 0px 0px .7vw #FFFFFF;

  color: $white;

  display: flex;

  justify-content: center;

  margin: 4.4% 0 2.2%;

  position: relative;

  width: 100%;

  z-index: 1;


  .P-beforeBackgroundLeft {
    aspect-ratio: 1.61;
    width: 25%;
    height: auto;
  }


  .P-beforeBackgroundLeft {
    bottom: 2%;

    left: -6%;

    position: absolute;

    transition: all .5s ease-in-out;

    z-index: -99;

  }

  &:hover {
    .P-beforeBackgroundLeft {
      left: -8%;
    }
  }

  & > *:not(.P-beforeBackgroundLeft,.P-yogaBackground) {
    left: 50%;

    position: absolute;

    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s ease-in-out;

  }

  &:hover > *:not(.P-beforeBackgroundLeft,.P-yogaBackground) {
    top: 100%;
    transform: translate(-50%, -100%);

  }

  &:before, &:after {

    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

    content: "";

    display: block;

    position: absolute;

    transition: all .5s ease-in-out;

    z-index: 1;

  }

  &:hover:after {
    right: -5%;
  }

  &:hover:before {
    left: -8%;
  }

  &:after {
    right: -3%;
  }


  &:before {
    left: -6%;
  }

  &.yoga {
    box-shadow: none;

    .P-yogaBackground {
      border-radius: 100vw;
      box-shadow: 0px 0px .7vw #FFFFFF;

      height: 100%;

      object-fit: cover;

      position: absolute;

      width: 100%;
    }

    &:after,
    &:before {
      aspect-ratio: 1.56;
      width: 25%;
    }


    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaLeftFront.webp");
      bottom: 2%;

    }

    &:after {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaRightFront.webp");
      bottom: 0;
    }


  }


  &.live {
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/liveBackground.webp");

    &:after,
    &:before {
      aspect-ratio: 1;
      width: 25%;
    }

    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/liveLeftFront.webp");
      top: -25%;
    }

    &:after {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/liveRightFrontpng.webp");
      bottom: -15%;
    }

  }

  &.home {
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homeBackground.webp");

    &:after,
    &:before {
      aspect-ratio: 1;
      width: 25%;
    }

    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homeLeftFront.webp");
      top: -25%;
    }

    &:after {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/homeRightFront.webp");
      bottom: -15%;
    }

  }

}