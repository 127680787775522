@import "src/assets/style";

.icon-bin {

  position: absolute;
  background: $error;
  padding: .5vw;
  color: $white;
  border-radius: 50%;
  @include screen-portrait {
    padding: 1.5vw;

  }
}

.P-socialIcons {
  display: flex;
  flex-wrap: wrap;
  margin: 1vw;
  gap: 1vw;

  .P-socialElement {
    width: 5vw;
    @include screen-portrait {
      width: 15vw;
    }

    .P-delete {
      display: none;

    }

    &:hover {
      text-shadow: 0px 0px .7vw $white;
      @include screen-portrait {
        text-shadow: 0px 0px 2.7vw $white;

      }

      .P-delete {
        display: block;
      }
    }
  }

  @include screen-portrait {
    margin: 3vw;
    gap: 3vw;

  }
}

