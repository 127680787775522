@import "src/assets/style";


.G-form-file-upload {
  position: relative;
  width: 100%;


  .G-input-file-upload {
    display: none;
  }

  .G-label-file-upload {
    cursor: pointer;
    margin-top: .5vw;
    @include screen-portrait {
      margin-top: 1.5vw;
    }

    .G-file-name-container {
      cursor: auto;

      .icon {
        cursor: pointer;

      }
    }

    div:not(.P-loading) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 1vw;
      height: 3vw;
      background-color: $secondary-color-purple;
      border: .2vw dashed $secondary-color-purple-light;
      border-radius: 3vw;
      color: $secondary-color-purple-light;
      @include screen-portrait {
        height: 9vw;
        border-radius: 9vw;
        border-width: .4vw;
        padding: 0 3vw;

      }

      p {
        text-align: center;
      }

      .icon {
        background-color: transparent;
        font-size: 1.2vw;
        @include screen-portrait {
          font-size: 3.6vw;
        }
      }
    }
  }

  .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

}