@import "src/assets/style";

.P-adminPage {
  background: $secondary-color-purple;
  min-height: 100%;
  display: flex;
  padding-top: 4.1vw;
  max-height: 100%;
  @include screen-portrait {
    padding-top: 16.3vw;

  }

  .P-sideBar {
    background: $secondary-color-purple;
    width: fit-content;
    font-size: 1.4vw;
    color: $white;
    min-height: 100%;
    box-shadow: .2vw .2vw .5vw 0px rgba(50, 5, 82, 0.30);
    display: flex;
    flex-direction: column;
    padding-top: 1vw;
    overflow-x: hidden;
    max-width: 30vw;
    position: relative;
    min-width: 20vw;
    transition: all .2s ease-in-out;
    @include screen-portrait {
      min-width: 100vw;
      max-width: 100vw;
      box-shadow: .6vw .6vw 1.5vw 0px rgba(50, 5, 82, 0.30);
      padding-top: 3vw;
      font-size: 4.4vw;

    }

    * {
      max-width: 30vw;
      transition: all .2s ease-in-out;
      opacity: 1;
      @include screen-portrait {
        max-width: 100vw;

      }

    }

    &.close {
      max-width: 5vw;
      min-width: 5vw;
      @include screen-portrait {
        min-width: 15vw;
        max-width: 15vw;

      }

      *:not(.P-sideBarIcon) {
        max-width: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
      }
    }

    .P-sideBarIcon {
      font-size: 3vw;
      margin: 0 1vw 0 auto;
      @include screen-portrait {
        font-size: 9vw;
        margin: 0 3vw 0 auto;
      }
    }

    a {
      color: $white;
      font-size: 1.4vw;
      width: 100%;
      padding: 1vw 2vw;
      white-space: nowrap;
      @include screen-portrait {
        font-size: 4.4vw;
        padding: 3vw 6vw;
      }

      &.active {
        background: $Contrast_colors-dark;
        color: $secondary-color-purple;
      }
    }

    .P-dropDownLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .P-dropDown {
      max-height: 0;
      overflow: hidden;
      transition: all .2s ease-in-out;

      &.open {
        max-height: 20vw;
        @include screen-portrait {
          max-height: 60vw;

        }
      }

      a {
        margin-left: 2vw;
        padding: .5vw 2vw;
        @include screen-portrait {
          margin-left: 6vw;
          padding: 1.5vw 6vw;
        }

        &.active {
          background: transparent;
          color: $Contrast-colors-dark;
        }
      }
    }
  }

  .P-circleInput {
    cursor: pointer;
    width: 60%;
    aspect-ratio: 1;
    height: fit-content;
    border-radius: 50%;
    box-shadow: 0px 0px .7vw $secondary-color-purple-light;
    margin: .5vw;
    margin-left: 0;
    display: block;
    position: relative;
    @include screen-portrait {
      box-shadow: 0px 0px 2.7vw $secondary-color-purple-light;
      margin: 1.5vw;
    }

    input {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: auto;
      width: auto;
      opacity: 0
    }
  }

  .P-subPages {
    padding: 2vw;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    //
    .P-addButton {
      min-width: 20vw;
      min-height: 3vw;
      border-radius: 2vw;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1vw;
      color: $white;
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      @include screen-portrait {
        min-width: 60vw;
        min-height: 9vw;
        border-radius: 6vw;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 3vw;
      }

      &:hover {
        background: $Contrast_colors-dark;
        color: $secondary-color-purple;
        border: .2vw solid $Contrast_colors-dark;
        @include screen-portrait {
          border: .6vw solid $Contrast_colors-dark;

        }
      }
    }

    .P-pageContent {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      @include screen-portrait {
        .G-flex {
          flex-direction: column;
        }
      }

      .P-title {
        font-size: 4vw;
        color: $secondary-color-purple-light;
        font-weight: 600;
        @include screen-portrait {
          font-size: 12vw;
          text-align: center;
        }
        //margin-left: 2vw;

      }

      .P-subTitle {
        font-size: 2vw;
        color: $secondary-color-purple-light;
        width: 100%;
        padding: 0 2vw;
        font-weight: 600;
        @include screen-portrait {
          font-size: 6vw;
          padding: 0 6vw;

        }
      }
    }

    .P-inputsContainer {
      width: calc(100% + 4vw);
      margin: 0 -2vw;

      .P-inputBox {
        width: 50%;
        padding: .5vw 2vw;


        &:has(.P-circleInput) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include screen-portrait {
          width: 100%;
          padding: 1.5vw 2vw;

        }

        //&:first-child {
        //  width: 100%;
        //}
      }
    }

    .P-actionsSection {
      display: flex;
      justify-content: flex-end;
      gap: 2vw;
      margin: auto 0 2vw;
      @include screen-portrait {
        gap: 6vw;
        margin: auto 0 6vw;
        flex-direction: column;
        align-items: center;
      }

      button {
        min-width: 20vw;
        min-height: 3vw;
        border-radius: 2vw;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1vw;
        color: $white;
        border: 0.2vw solid;

        @include screen-portrait {
          min-width: 100%;
          min-height: 9vw;
          border-radius: 6vw;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 3vw;
          border: 0.4vw solid;

        }

        &:hover {
          background: $Contrast_colors-dark;
          color: $secondary-color-purple;
          border: .2vw solid $Contrast_colors-dark;
          @include screen-portrait {
            border: .6vw solid $Contrast_colors-dark;

          }
        }
      }

      .P-primaryBTN {
        background-color: $secondary-color-purple-light;
        border-color: $secondary-color-purple-light;
      }

      .P-secondaryBTN {
        border-color: $secondary-color-purple-light;
        background-color: $secondary-color-purple;
      }
    }

  }

  .P-try {
    color: $secondary-color-purple-light;
    margin: .5vw;
    text-decoration: underline;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    direction: rtl;
    white-space: nowrap;
    @include screen-portrait {
      margin: 1.5vw;
    }

    &:hover {
      color: $Contrast_colors-dark;
    }
  }

  .P-label {
    color: $secondary-color-purple-light;
    margin: .5vw;
    font-weight: 600;
    font-size: .8vw;
    @include screen-portrait {
      margin: 1.5vw;
      font-weight: 600;
      font-size: 2.8vw;

    }

    .P-required {
      margin-left: .2vw;
      @include screen-portrait {
        margin-left: .7vw;
      }
    }


  }

  .P-primaryBTN {
    background-color: $secondary-color-purple-light;
    border-color: $secondary-color-purple-light;
  }

  .P-secondaryBTN {
    border-color: $secondary-color-purple-light;
    background-color: $secondary-color-purple;
  }

  .P-textArea {
    &:has(:focus) {
      .P-label {
        color: $Contrast_colors-dark;

      }
    }

    .P-input {
      width: 100%;
      border-radius: 1vw;
      padding: .5vw;
      border: .1vw solid $secondary-color-purple-light;
      background-color: $secondary-color-purple;
      color: $secondary-color-purple-light;
      font-size: 1vw;
      @include screen-portrait {
        font-size: 4vw;
        border-width: .3vw;
        border-radius: 3vw;
        padding: 1.5vw;
      }

      &:focus {
        border-color: $Contrast_colors-dark;
        color: $Contrast_colors-dark;
      }
    }


  }

  .P-viewsContainer {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2vw);
    margin: 0 -1vw;

    .P-radioBox {
      padding: 1vw;
      width: 50%;
      @include screen-portrait {
        width: 100%;
      }

      .P-radio {
        padding: 1vw;
        width: 100%;
        border: .2vw solid $secondary-color-purple-light;
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        @include screen-portrait {
          border-radius: 3vw;
          border-width: .3vw;
          padding: 3vw;
        }

        &:has(.checked) {
          .P-images {
            img {
              box-shadow: 0px 0px .7vw $white;
              @include screen-portrait {
                box-shadow: 0px 0px 2.7vw $white;

              }
            }
          }
        }

        .P-images {
          max-width: 100%;
          display: flex;
          justify-content: space-between;
          aspect-ratio: 190 /66;
          margin-bottom: 1vw;
          @include screen-portrait {
            //flex-direction: column;
            margin-bottom: 3vw;
          }

          img {
            max-height: 100%;
            height: 100%;
            width: fit-content;
            object-fit: contain;
            border-radius: .5vw;
            box-shadow: 0px 0px .7vw $secondary-color-purple-light;
            @include screen-portrait {
              border-radius: 1.5vw;
              box-shadow: 0px 0px 2.7vw $secondary-color-purple-light;
            }

          }
        }

        .P-customRadio {
          width: 1vw;
          aspect-ratio: 1;
          background-color: $secondary-color-purple;
          border-radius: 50%;
          border: .2vw solid $secondary-color-purple-light;
          margin: 0 auto;
          @include screen-portrait {
            width: 3vw;
            border-width: .6vw;
          }

          &.checked {
            background-color: $Contrast_colors-dark;

          }
        }

        input {
          display: none;
        }
      }
    }
  }

}

.P-deleteContainer {
  position: relative;
  width: fit-content;

  .P-delete {
    position: absolute;
    display: none;
    opacity: 0;
    right: -1vw;
    top: -1vw;
  }

  &:hover {
    .P-delete {
      position: absolute;
      display: block;
      opacity: 1;
    }
  }
}