@import "src/assets/style";

.P-top {
  padding: 1vw 0;
}

.P-title {
  font-size: 4vw;
  color: $main-color;
  font-weight: 600;
  @include screen-portrait {
    font-size: 6vw;
  }

}

.P-meditationAdminPageContainer {


  .P-meditationAdminPage {
    display: flex;
flex-wrap: wrap;

    .P-tab {
      width: 9vw;
      color: $white;
      padding: 1vw;
      position: relative;
      @include screen-portrait {
        width:40vw;
      }

      .P-meditationTab {
        position: relative;
      }

      .P-delete {
        display: none;
        z-index: 2;
        top: 0;
        right: 0;
      }

      &:hover {
        .P-meditationTab {
          transform: scale(1.1);
        }

        .P-delete {
          display: block;
        }
      }

      p + p {
        max-width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    .P-meditationTab {
      aspect-ratio: 1;
      width: 7vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto .5vw;
      border-radius: 50%;
      box-shadow: 0px 0px 18px -4px rgba(255, 255, 250, 0.6);
      overflow: hidden;
      transition: all .1s ease-in-out;


      @include screen-portrait {
        width: 20vw;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

      }
    }
  }
}
