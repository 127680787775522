@import "src/assets/style";

.P-eachMeditationDayAdminPage {

  .P-choices {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    align-items: stretch;
    gap: 2vw;
    margin-bottom: 4vw;
    padding: 5vw 0;
    //justify-content: center;

    @include screen-portrait {
      gap: 6vw;
    }

    .P-choice {
      width: 20%;
      aspect-ratio: 1;
      color: $white;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='26' ry='26' stroke='white' stroke-width='6' stroke-dasharray='15' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 26px;
      padding: 3px;
      //padding: 2vw;
      justify-content: center;
      align-items: center;
      display: flex;
      @include screen-portrait {
        height: 20vw;
        width: 36vw;
      }
    }
  }


  .P-audioContainer {
    width: 25%;
    border: 1px solid $white;
    border-radius: 1vw;
    padding: 1vw;
    margin: 1vw;
    @include screen-portrait {
      width: 100%;
    }

  }

}



.P-eachMeditationDayAdminPage .P-choices .P-choice a {
  border-radius: 24px;
  display: block;
  overflow: hidden;
}