@import "src/assets/style";

.P-audioPopUp {
  display: flex;
  color: $white;

  &.mainMusic {
    .P-description {
      text-align: start;
      margin-left: 2vw;
    }

    .P-musicTextContainer {
      &:before {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/left.png");
      }

      &:after {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/right.png");
      }
    }
  }

  &.musicWithTextCenter,
  &.musicWithText {
    .P-description {
      text-align: end;
    }

    .P-musicTextContainer {
      &:before {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/star.png");
      }

      &:after {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/star.png");
      }
    }
  }

  &.musicWithTextCenter {
    text-align: center;
  }

  &.musicWithoutText {
    .P-description {
      text-align: end;
    }

    .P-musicTextContainer {
      &:after, &:before {
        content: none;
      }
    }

    & > .G-container > .G-flex {
      margin-top: auto;
      margin-bottom: 30vw;
      @include screen-portrait {
        margin-bottom: 5vw;
      }

      .P-textContainer {
        width: 100%;
        text-align: center;

        .P-musicTextContainer {
          .P-textWrapper {
            width: 100%;
            mask: none;
            -webkit-mask: none;
            height: auto;
            overflow: unset;
            padding-bottom: 2vw;

            .P-musicText {
              transform: none !important;
              padding: 2vw 0 2vw;
            }
          }
        }
      }
    }

  }

  .G-container {
    position: relative;
    display: flex;
    flex-direction: column;
    @include screen-portrait {
      position: static;
    }
  }

  .P-icons {
    position: absolute;
    //color: #C6F91F;
    cursor: pointer;
    right: 0.5vw;
    width: 1.5vw;
    top: 1vw;
    font-size: 1.2vw;
    display: flex;
    align-items: center;
    min-width: 1.5vw;
    margin-left: 0.55vw;
    display: flex;
    flex-direction: column;
    @include screen-portrait {
      position: relative;
      right: auto;
      width: 8.6vw;
      font-size: 4vw;

    }

    span {
      background-color: $secondary-color-purple;
      width: 2.5vw;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.2vw;
      @include screen-portrait {
        width: 8.6vw;

      }

      &.active {
        background-color: $secondary-color-purple-light;

      }

      &.icon-wav, &.icon-mp3 {
        font-family: $Poppins-Regular !important;
        font-size: .8vw;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2.5vw;
        @include screen-portrait {
          font-size: 3vw;
        }
      }

      &.icon-wav {
        margin-top: 0vw;

        @include screen-portrait {
          margin-top: 5vw;
        }
      }
    }
  }

  .P-actionsContainer {
    margin-top: 2.5vw;
    display: block;
    @include screen-portrait {
      order: 1;
      display: flex !important;
      justify-content: space-between;
      width: 100% !important;
      flex-direction: row !important;
      align-items: flex-start;
      margin-top: 5vh;
      margin-bottom: 40vh;

    }


  }

  .P-textContainer {
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
    @include screen-portrait {
      margin-right: auto;
      margin-left: 0;
    }

    .P-musicTextContainer {
      position: relative;
      padding: 0 2vw;

      &:before {
        content: "";
        width: 1.5vw;
        aspect-ratio: 1/1.2;
        display: block;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &:after {
        content: "";
        width: 1.5vw;
        aspect-ratio: 1/1.2;
        display: block;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      @include screen-portrait {
        padding: 0 8vw;

        &:before, &:after {
          width: 7vw;
        }
      }

      .P-textWrapper {
        --mask: linear-gradient(to bottom,
                rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%,
                rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 1) 45%,
                rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 0.5) 60%,
                rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0) 100%
        ) 100% 100% / 100% 100% repeat-y;
        height: 20vw;
        overflow-y: auto;
        mask: var(--mask);
        position: relative;
        width: fit-content;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        @include screen-portrait {
          height: 45vw;
        }


        .P-musicText {
          white-space: pre;
          //animation: scrollAnimation 1s linear forwards;
          transition: 0.1s ease-in-out;
          font-family: $KronaOne-Regular, Arial;
          font-size: 1.5vw;
          padding-bottom: 10vw;
          @include screen-portrait {
            font-size: 4vw;
          }

        }
      }
    }

    .P-description {
      color: $Contrast_colors-dark;
      font-size: 1.02vw;
      white-space: pre;
      font-family: $Poppins-Medium;
      @include screen-portrait {
        text-align: start;
        font-size: 4vw;
        margin: 1vw 0 0 8vw;

      }
    }
  }

  .P-comment {
    color: $Contrast_colors-dark;
    font-size: 1.02vw;
    white-space: pre;
    margin-bottom: 5vw;
    margin-top: auto;
    width: 50%;
    font-family: $KronaOne-Regular;
    text-transform: none;
    @include screen-portrait {
      position: absolute;
      bottom: 0;
      margin-bottom: 10vw;
      font-size: 4vw;
    }
  }

}

