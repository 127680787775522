@import "src/assets/style";

.P-exclusivePage {
  height: calc(100% - 3vw);
  padding-top: 5.1vw;
  width: 100%;
  @include screen-portrait {
    padding-top: 17.8vw;
    min-height: calc(100% - 23vw);

  }


  &:before {
    content: "";
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/exclusivePage/background.webp");
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $secondary-color-purple;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    @include screen-portrait {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/exclusivePage/background.webp");

    }

  }

  .G-container {
    position: relative;
  }

  .P-backArrowContainer {
    margin: 2.5vw 0;
    display: block;
  }

  .P-pageContent {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include screen-portrait {
      flex-direction: column;
      justify-content: flex-start;
    }

    .P-playersSection {
      width: 100%;
      overflow-x: auto;
      height: calc(100% - 20vh);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2vw;
      column-gap: 5vw !important;

      .P-player {
        width: 33%;
        @include screen-portrait{
          width: 100%;
        }
      }

      @include screen-portrait {
        width: 100%;
        height: calc(100% - 30vh);

      }

      &::-webkit-scrollbar-thumb {
        background-color: $Contrast_colors-dark;
      }
    }
  }

  .P-basketSection {
    position: fixed;
    right: 10vw;
    bottom: 10vw;
    @include screen-portrait {
      bottom: 15vw;
    }

    .P-content {
      background-color: $secondary-color-purple;
      position: absolute;
      bottom: 100%;
      right: 0;
      border-radius: 1vw;
      padding: 0;
      box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      overflow: hidden;
      max-height: 0;
      transition: all .3s ease-in-out;
      transform-origin: bottom;

      &.active {
        max-height: 23vw;
        padding: .2vw 0 0;

      }

      @include screen-portrait {
        width: 80vw;
        &.active {
          max-height: 95vw;
        }
      }

      .P-bayAll {
        width: 100%;
        height: 3vw;
        background-color: $secondary-color-purple-light;
        text-transform: uppercase;
        color: $white;
        font-weight: 600;
        @include screen-portrait {
          height: 15vw;
        }

        &:hover {
          background-color: $Contrast_colors-dark;
          color: $secondary-color-purple;
        }
      }

      .P-playersSection {
        overflow-y: auto;
        flex-wrap: nowrap;
        flex-direction: column;
        max-height: 20vw;
        padding: 1vw;
        @include screen-portrait {
          max-height: 80vw;
        }
      }


    }

    .icon-shopping-cart {
      font-size: 3vw;
      background-color: $Contrast_colors-dark;
      padding: 1vw;
      width: fit-content;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $secondary-color-purple;
      position: relative;
      @include screen-portrait {
        font-size: 9vw;
        padding: 3vw;
      }

      .P-count {
        position: absolute;
        font-size: 1.2vw;
        vertical-align: middle;
        color: $white;
        top: 0;
        right: 0;
        background-color: $error;
        display: flex;
        justify-content: center;
        align-items: baseline;
        border-radius: 50%;
        aspect-ratio: 1;
        width: 1.5vw;
        @include screen-portrait {
          font-size: 4.6vw;
          width: 5vw;
        }
      }
    }
  }

  .P-actionsSection {
    width: fit-content;
    display: flex;
    flex-direction: column;
    @include screen-portrait {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 7vh;
    }
  }

  .P-exclusiveButton {
    text-transform: uppercase;
    color: $white;
    font-size: 1.2vw;
    font-weight: bold;
    padding: .5vw .9vw .5vw 1.5vw;
    background-color: $main-color;
    width: 16vw;
    text-align: center;
    border-radius: 3vw;
    margin-bottom: 1.3vw;

    &.yellow {
      background-color: $Contrast-colors-light;
      color: $secondary-color-purple;
    }

    @include screen-portrait {
      font-size: 3.5vw;
      padding: 2vw 2vw 2vw 4vw;
      border-radius: 6vw;
      margin-bottom: 4.1vw;
      width: 44vw;

    }
  }
}