@import "src/assets/style";

.P-socialLinksPopUp {
  .P-title {
    font-size: 2.5vw;
    color: $Contrast_colors-dark;
    text-transform: uppercase;
    text-align: center;
    @include screen-portrait {
      font-size: 6vw;
    }
  }

  .P-iconsSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 25vw;
    max-height: 25vw;
    overflow-y: auto;
    @include screen-portrait {
      max-width: 70vw;
      width: 70vw;
      max-height: 70vw;
    }

    .P-iconBox {

      width: 4vw;
      padding: .5vw;
      @include screen-portrait {
        width: 15vw;
        padding: 2vw;
      }
    }
  }
}