@import "../../assets/style/index";


.P-exclusivePlayer {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  //min-width: 403px;
  color: $white;
  gap: 2%;

  .P-price {
    white-space: nowrap;
    font-size: 1.2vw;
    @include screen-portrait {
      font-size: 4vw;
    }
  }

  .P-title {
    font-size: 1.2vw !important;
    font-weight: bold !important;
    text-shadow: 0px .1vw .1vw $secondary-color-purple;
    line-height: 1;
    text-align: left !important;
    @include screen-portrait {
      font-size: 4vw !important;

    }

  }

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .25vw;
  }

  &.forSell {
    cursor: pointer;

    .P-playButton:hover {
      border-color: $Contrast_colors-dark;
      color: $Contrast-colors-dark;
    }

    .P-buyButton:hover {
      background-color: $main-color;
    }

  }


  .icon-cart-plus {
    font-size: 1.4vw;
    @include screen-portrait {
      font-size: 5vw;
    }

    &.active {
      color: $Contrast_colors-dark;
    }
  }

  @include screen-portrait {
    width: 100%;
  }

  .P-index {
    font-size: 1.2vw;
    text-align: right;
    flex-basis: 5%;
    @include screen-portrait {
      font-size: 4vw;
    }
  }

  .P-playButton {
    flex-basis: 15%;
    color: $Contrast_colors-light;
    background: none;
    aspect-ratio: 1;
    border-radius: 50%;
    border: .1vw solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    @include screen-portrait {
      font-size: 5vw;
    }

    .icon-play {
      transform: translate(10%, 5%);
    }

    &:hover {
      border-color: $Contrast_colors-dark;
      color: $Contrast-colors-dark;
    }
  }

  .P-infoSection {
    flex-basis: 50%;

    .P-title {
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0px .1vw .1vw 0px $secondary-color-purple;
      line-height: 1;
      @include screen-portrait {
        font-size: 4vw;

      }

    }

    .P-description {
      color: $secondary-color-pink-dark;
      font-size: .8vw;
      font-weight: 500;
      line-height: 1;
      @include screen-portrait {
        font-size: 3vw;
      }
    }
  }

  .P-buyButton {
    flex-basis: 24%;
    background: none;
    color: $white;
    font-weight: bold;
    font-size: 1.2vw;
    border: .1vw solid $main-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10vw;
    @include screen-portrait {
      font-size: 4vw;
    }

  }
}