@import "src/assets/style";

.App:has(.P-homePage) .P-footer {
  //@include screen-max-width-991 {
  //
  //  position: fixed;
  //}
}

.P-footer {
  background-color: $secondary-color-purple;
  height: 3vw;
  position: relative;

  width: 100%;
  box-shadow: 0 .8vw 2.5vw 2vw rgba(0, 0, 0, .6);
  z-index: 2;
  padding: .5vw 0;
  text-align: start;
  @include screen-portrait {

    position: relative;
    bottom: 0;
    padding: 1.5vw 0;

    height: 8vw;
  }

  .G-container {
    //flex-wrap: wrap;
  }

  .P-sectionTitle {
    color: $Contrast_colors-dark;
    font-size: .8vw;
    //margin-bottom: .3vw;
    line-height: 1;
    white-space: nowrap;
    @include screen-portrait {
      font-size: 3vw;
    }
  }

  .P-paymentsSection {
    @include screen-portrait {
      max-width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }


    .P-paymentsContainer {
      min-width: fit-content;
      height: 1.5vw;
      display: flex;
      gap: 10%;
      @include screen-portrait {
        width: 54%;
        height: 6.5vw;
      }

      .P-regLinks {
        color: $Contrast_colors-dark;
        font-size: .8vw;
        //margin-bottom: .3vw;
        line-height: 1;
        white-space: nowrap;
        text-decoration: underline;
        @include screen-portrait {
          font-size: 3vw;
          white-space: normal;
          text-align: center;
        }
      }

      img {
        max-height: 100%;
        object-fit: contain;
        //min-width: fit-content;
        display: block;

        &:not(:first-child) {
          margin: 0 .7vw;
        }
      }

    }
  }

  .P-copyrightSection {
    max-width: 20.4vw;
    font-size: .7vw;
    color: $Contrast_colors-dark;
    text-align: center;
    //min-width: 320px;
    //@include screen-max-width-991 {
    //  order: 3;
    //  margin-top: 20px;
    //  font-size: 11px;
    //}
  }

  .P-socialIconsContainer {

    .P-sectionTitle {
      text-align: end;
      @include screen-portrait {
        width: 100%;
        white-space: normal;
      }
    }

    @include screen-max-width-991 {
      width: 44%;
    }
  }
}