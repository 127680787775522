@import "src/assets/style";

.P-audioPlayer {
  display: flex;

  position: relative;

  &:has(.open) {
    z-index: 2;
  }

  &:hover {
    /*.P-textAsBanner {
      &:before {
        color: #0000FF;
        animation: glitcBlue 1.7s ease-in-out forwards;
      }

      &:after {
        color: #FF0000;
        animation: glitcRed 1.7s ease-in-out forwards;

      }
    }*/

    .P-banner {
      &:before {
        animation: glitcRed 1.7s ease-in-out forwards;

      }

      &:after {
        animation: glitcBlue 2s ease-in-out forwards;

      }


    }
  }

  .P-audioWavePlayer {
    position: absolute;
    margin-left: 5vw;
    bottom: -5vw;
    @include screen-portrait {
      position: absolute;
      margin-left: 12vw;
      bottom: -10vw;
    }
  }

  .P-textAsBanner {
    position: relative;
    z-index: 2;

    &:after,
    &:before {
      content: attr(data-text);
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0;
      z-index: 0;
      opacity: 0;
      mask-image: radial-gradient(#000 99%, transparent 100%);

      mask-repeat: no-repeat;
      mask-position: left;

    }


  }

  .P-banner {
    min-width: 11.5vw;
    aspect-ratio: 1;
    border-radius: 1vw;
    border: .1vw solid $Contrast_colors-dark;
    background-color: rgba(180, 41, 249, 0.23);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: relative;
    z-index: 1;


    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border-radius: 1vw;
      border: .1vw solid $Contrast_colors-dark;
      background-color: rgba(180, 41, 249, 0.23);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      z-index: -2;
      mask-image: radial-gradient(#000 99%, transparent 100%);

      mask-repeat: no-repeat;
      mask-position: left;
      @include screen-portrait {
        width: 40vw;
        border: .5vw solid $Contrast_colors-dark;
        border-radius: 8.6vw;
      }
    }


    &:before {
      filter: hue-rotate(90deg);

    }

    &:after {
      filter: hue-rotate(290deg);

    }

    @include screen-portrait {
      width: 40vw;
      border: .5vw solid $Contrast_colors-dark;
      border-radius: 8.6vw;

    }
  }

  .P-playButton {
    background-color: $Contrast_colors-dark;
    box-shadow: 0px 0px .35px $secondary-color-purple;
    width: 4.8vw;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2vw;
    color: $secondary-color-purple;
    font-size: 2vw;
    padding: .1vw 0 0 .1vw;
    transition: all .3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    margin: 0 .5vw;
    min-width: 4.8vw;

    [class*="icon"] {
      pointer-events: none;
    }

    @include screen-portrait {
      width: 18vw;
      min-width: 18vw;
      border-radius: 5.2vw;
      margin: 0 .5vw;
      font-size: 7.6vw;
      box-shadow: 0px 0px 3.5px $secondary-color-purple;


    }
  }


  .P-title {
    font-family: $Poppins-SemiBold;
    font-size: 1.6vw;
    line-height: 2.5vw;
    color: $white;
    white-space: nowrap;
    position: relative;
    text-shadow: 0.5vw 0.5vw 0.4vw #5A108F;
    @include screen-portrait {
      font-size: 5.3vw;
      line-height: 6vw;
    }
  }

  .P-comingSoon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: .8vw;
    opacity: 0;
    top: 0;
    transition: all .3s ease-in-out;
    pointer-events: none;

    &.false {
      opacity: 1;
    }

    @include screen-portrait {
      font-size: 3vw;

    }
  }

  &.left {
    flex-direction: row-reverse;
    align-items: center;


    &:has(.P-banner) .P-comingSoon {
      &.false {
        top: 50%;
        @include screen-portrait {
          top: 100%;
        }
      }
    }

    &:has(.P-banner) .P-playButton {
      margin: 0 -1vw auto -3vw;
      @include screen-portrait {
        margin: 2vw -3vw auto -13vw;
      }
    }

    &:has(.P-banner) .P-title {
      margin-top: 1vw;
      @include screen-portrait {
        margin-top: 7vw;
      }
    }

    .P-banner {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/audioPlayer/leftGirl.webp");

      &:after, &:before {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/audioPlayer/rightGirl.webp");

      }
    }
  }

  &.right {

    align-items: center;

    .P-comingSoon {
      top: 50%;
      transform: translate(0, -50%);


      &.false {
        left: 50%;
        top: 40%;

      }
    }

    &:has(.P-banner) .P-comingSoon {
      left: 5%;
      transform: translate(-50%, 0%);

      &.false {
        top: -120%;
      }
    }

    &:has(.P-banner) .P-playButton {
      margin: auto .5vw 1vw -1.5vw;
      @include screen-portrait {
        margin: auto 2vw 4vw -1.5vw;

      }
    }

    &:has(.P-banner) .P-title {
      margin-top: 5vw;
      @include screen-portrait {
        margin-top: 16vw;
      }
    }

    .P-banner {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/audioPlayer/leftGirl.webp");

      &:after, &:before {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/audioPlayer/leftGirl.webp");

      }
    }

  }
}

@keyframes glitcRed {
  0% {
    opacity: .5;
    transform: translateX(-5%);
  }
  4% {
    opacity: .5;
    transform: translateX(-5%);
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: .5;
    transform: translateX(5%);

  }
  14% {
    opacity: .5;
    transform: translateX(5%);
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: .51;
    transform: translate(-5%, 5%);

  }
  24% {
    opacity: .5;
    transform: translate(-5%, 5%);
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: .5;
    transform: translateX(5%);
  }
  34% {
    opacity: .5;
    transform: translateX(5%);
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: .5;
    transform: translate(-5%, -5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  44% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: .5;
    transform: translate(5%, 5%);
    mask-size: 100% 50%;
    -webkit-mask-size: 100% 50%;
  }
  54% {
    opacity: .5;
    transform: translate(5%, 5%);
  }
  55% {
    opacity: 0;
  }


}

@keyframes glitcBlue {
  0% {
    opacity: .5;
    transform: translateX(5%);
    mask-size: 100% 50%;
    -webkit-mask-size: 100% 50%;
  }
  4% {
    opacity: .5;
    transform: translateX(5%);
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: .5;
    transform: translateX(-5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  14% {
    opacity: .5;
    transform: translateX(-5%);
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: .5;
    transform: translate(5%, -5%);
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }
  24% {
    opacity: .5;
    transform: translate(5%, -5%);
    mask-position: bottom;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: .5;
    transform: translate(-5%, 5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  34% {
    opacity: .5;
    transform: translate(-5%);
    mask-size: 10% 100%;
    -webkit-mask-size: 10% 100%;
    mask-position: center;

  }
  35% {
    opacity: 0;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }
  40% {
    opacity: .5;
    transform: translateX(5%);
  }
  44% {
    opacity: .5;
    transform: translateX(5%);
  }
  45% {
    opacity: 0;
  }

  50% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  54% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  55% {
    opacity: 0;
  }


}

