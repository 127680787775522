@import "src/assets/style";

.P-socialIconsSection {
  display: flex;
  align-items: flex-end;
  gap: 1vw;
  height: fit-content;
  @include screen-portrait {
    gap: 2.5vw;
  }

  a {

    display: flex;
    height: 1.8vw;
    @include screen-portrait {
      height: auto;
    }
  }

  img {
    min-width: 1.8vw;
    width: 1.8vw;
    aspect-ratio: 1;
    object-fit: contain;
    height: auto;
    @include screen-portrait {


      width: 12vw;
    }
  }

  .P-dots {
    color: #FFFFFF;
    font-size: 2vw;
    height: fit-content;
    letter-spacing: .1vw;
    line-height: 1;
    @include screen-portrait {
      font-size: 6vw;
      letter-spacing: .5vw;
    }
  }
}