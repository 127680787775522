@import "flex";
@import "src/assets/icons/style.css";
@import "normalize";
@import "global";
@import "variables";
@import "mixin";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
html, body {
  overflow: hidden;
}

body {
  position: relative;
}

body :is(#root,.App,.G-container) {
  height: 100%;
}

.G-mainContainer {
  height: calc(100% - 3vw);
  @include screen-portrait {
    height: calc(100% - 8vw);

  }
}

.G-loaderContainer {
  position: relative;
  width: 6vw;
  height: 2.6vw;
  margin-top: -1.2vw;
  @include screen-portrait {
    width: 6vw;
    height: 6.6vw;
    margin-top: -4.2vw;
  }

}

.G-loader,
.G-loader:before,
.G-loader:after {
  background: $main-color;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.G-loader {
  color: $main-color;
  text-indent: -9999em;
  //margin: 88px auto;
  position: absolute;
  left: 10%;
  font-size: 6px;
  margin: auto;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.G-loader:before,
.G-loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.G-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.G-loader:after {
  left: 1.5em;
}


@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


div:where(.swal2-container).swal2-center > .swal2-popup {
  background: $secondary-color-purple;
  color: $white !important;
  border: .2vw solid $Contrast_colors-dark;
  border-radius: 2vw;
}


div:where(.swal2-icon) {
  margin: 2.5em auto 0.6em;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  //border-color: #000;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  background: $error;
}

div:where(.swal2-icon).swal2-error {
  border-color: $error !important;
  color: $error;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  width: 50%;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  width: 100%;
  border-radius: 3vw;
  background-color: $error !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {

  background: $secondary-color-purple-light !important;
  font-weight: 600;
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
  width: 100%;
  border-radius: 2vw;

  &:hover {
    background: $Contrast_colors-dark !important;
    color: $secondary-color-purple;
  }
}