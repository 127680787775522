@import "src/assets/style";

.P-backArrowContainer {
  img {
    width: 9.6vw;
    @include screen-portrait {
      width: 28vw;
    }
  }

  span {
    text-transform: lowercase;
    margin-left: -.5vw;
    margin-top: -.3vw;
    font-family: $Poppins-SemiBold;
    color: $secondary-color-purple-light;
    font-size: 1.2vw;
    @include screen-portrait {
      font-size: 5vw;
      margin-left: -1.5vw;
      margin-top: -1.7vw;
    }
  }
}