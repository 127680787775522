@import "src/assets/style";

.P-yogaPage {
  //height: calc(100% - 3vw);

  position: relative;
  z-index: 0;
  padding-top: 4.1vw;
  width: 100%;
  overflow-x: hidden;

  .G-mainContainer {
    display: flex;
    justify-content: space-between;

    @include screen-portrait {
      flex-direction: column;
      height: calc(100% - 23vw);

    }
  }

  &:before {
    content: "";
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/BACKGROUND.webp");
    height: auto;
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: $secondary-color-purple;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;

  }

  &:after {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    content: "";
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/PLANTS.webp");
    background-size: cover;
    background-position: center 35%;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  @include screen-portrait {
    padding-top: 15vw;
    overflow: hidden;
    height: calc(100%);

    &:before {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/BACKGROUNDmobile.webp");

    }
    &:after {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/PLANTSmobile.webp");
    }
  }

  .G-container {
    height: calc(100% - 8.5vw) !important;
    //overflow-y: hidden;
    @include screen-portrait {
      height: auto;
      overflow-y: visible;
      height: calc(100% - 29.3vw) !important;
      &:before {
        content: "";
        position: absolute;
        height: 150.5vw;
        max-height: 100%;
        width: 140%;
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/TREE.webp");
        background-size: cover;
        background-repeat: no-repeat;
        top: 17vw;
        left: 3vw;
        pointer-events: none;
      }

    }
  }

  .P-audioWavePlayer {
    margin: 0;
    @include screen-portrait {
      margin: 0 12vw;
    }
  }

  .P-titleAndActionsContainer {
    margin: 1.4vw 0 2.5vw;
    position: relative;
    z-index: 2;
    @include screen-portrait {
      display: block;
    }

    .P-titleSection {
      margin-bottom: .52vw;
      @include screen-portrait {
        text-align: center;
        transform-origin: top;
        margin-bottom: 0px;

      }

      .P-mainText {
        font-size: 2.65vw;
        color: $white;
        text-shadow: .05vw .05vw .15vw $secondary-color-purple;

        .G-yoga {
          font-size: 3.5vw;
        }
      }

      .P-subText {
        font-size: 1.46vw;
        text-shadow: .05vw .05vw 0px $secondary-color-purple;

      }

      @include screen-portrait {
        .P-mainText {
          font-size: 9.65vw;
          color: $white;
          text-shadow: .5vw .5vw 1.5vw $secondary-color-purple;
          line-height: 1;

          .G-yoga {
            font-size: 12.65vw;
          }
        }

        .P-subText {
          font-size: 5.46vw;
          text-shadow: .5vw .5vw 0px $secondary-color-purple;
          line-height: 1;
          margin-top: -.5vw;

        }
      }
    }

    .P-actionsSection {
      gap: 2vw;

      .P-button {
        width: 16.67vw;

        .P-homeTitle {
          font-size: 1.72vw;
          text-align: center;
          margin-bottom: -1vw;
          text-shadow: .05vw .05vw .15vw $secondary-color-purple;

        }

        .P-liveTitle {
          color: $white;
          font-size: 2.65vw;
          text-align: center;
          margin-bottom: -1vw;
          text-shadow: .05vw .05vw .4vw $secondary-color-purple;

        }

        .G-live,
        .G-home {
          font-size: 2.65vw;
        }


      }

      @include screen-portrait {
        margin-top: 5vw;
        justify-content: space-between;
        .P-button {
          width: 48%;

          .P-homeTitle {
            font-size: 4vw;
            margin-bottom: -2vw;
            text-shadow: .5vw .5vw 1.5vw $secondary-color-purple;

          }

          .P-liveTitle {
            font-size: 5.6vw;
            margin-bottom: -2vw;
            text-shadow: .5vw .5vw .4vw $secondary-color-purple;

          }

          .G-live,
          .G-home {
            font-size: 7.9vw;
          }
        }
      }
    }
  }

  .P-playersSection {
    max-height: calc(100vh - 18vw);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: fit-content;
    min-width: 50%;
    max-width: 60%;
    mask-image: linear-gradient(#000 80%, transparent);
    //padding-top: 2.5vw;
    @include screen-portrait {
      min-height: unset;
      max-height: 30vh;
      max-width: 100%;

    }

    section {
      width: 50%;
      @include screen-portrait {
        width: 100%;
      }
    }

    .test {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -20% 40%;
      z-index: 11;
      @include screen-portrait {
        translate: 20% 0%;
        position: relative;

      }
    }

    .P-present {
      margin: .8vw 0 .8vw;

      .P-presentText {
        .P-info {
          font-size: 1.25vw;
          margin-top: -.8vw;
          font-family: $Poppins-Medium;

          .P-colored {
            color: $Contrast_colors-dark;
            letter-spacing: .4vw;
          }
        }

        @include screen-portrait {
          transform-origin: left;
          .P-info {
            font-size: 4vw;

            .P-colored {
              color: $Contrast_colors-dark;
              letter-spacing: 1.7vw;
            }
          }
        }
      }
    }

    .P-future {
      position: relative;

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      display: flex;
      justify-content: flex-end;

      .P-playerContainer {
        position: absolute;
        margin-top: 2vw;
      }

      @include screen-portrait {
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        .P-playerContainer {
          position: relative;
          top: auto;
          left: auto;
          transform: translateX(0%);
        }
      }

      &:before {
        content: "";
        position: absolute;
        height: 41vw;
        //max-height: 100%;
        width: 40.5vw;

        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/TREE.webp");
        background-size: cover;
        background-repeat: no-repeat;
        top: -16vw;
        left: 0;
        pointer-events: none;
        z-index: 0;
        @include screen-portrait {
          content: none;
        }
      }
    }

    .P-past {
      @include screen-portrait {
        margin-top: 12vw;
      }
    }
  }
}


section.P-playersSection:has(.open) {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: visible;
  mask-image: unset;
}