@import "src/assets/style";

.P-YogaMusic {
  .P-top {
    margin-bottom: 1vw;
    @include screen-portrait {
      margin-bottom: 4vw;
    }
  }

  .P-present {
    padding: 1vw;
    @include screen-portrait {
      padding: 4vw;
    }

    .P-presentText {
      font-size: 2vw;
      @include screen-portrait {
        font-size: 6vw;
      }

      .P-info {
        font-size: 1vw;
        @include screen-portrait {
          font-size: 3vw;
        }
      }
    }
  }
}