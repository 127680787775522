@import "./variables";
@import "./mixin";
@import "./font";

.G-desktop {
  @include screen-portrait {
    display: none !important;
  }
}

.G-mobile {
  @include screen-landscape {
    display: none !important;
  }
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: $main-color;

}

.G-PopUp {
  display: none;
  position: fixed;
  //width: 100vw;
  //height: 100vh;
  padding-top: 4.1vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 15;
  background: rgba(0, 0, 0, .91);
  @include screen-portrait {
    padding-top: 16.3vw;
  }

  &.open {
    display: block;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-color-pink-dark;
  border-radius: 20px;
}

.G-hidden {
  overflow: hidden;
}

.G-container {
  max-width: 66vw;
  width: 100%;
  padding: 0px 4vw;
  margin: 0 auto;
  @include screen-portrait {
    padding: 0;
    max-width: calc(90vw + 4vw);

  }
}


.comingSoon {
  cursor: default;
  position: relative;

  &:hover {
    color: transparent !important;
    text-shadow: none !important;

    &:before {
      max-height: 100%;
      color: $white;
    }
  }


  &:before {
    content: "Coming Soon";
    text-transform: uppercase;
    font-size: .6vw;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    white-space: nowrap;

  }

  @include screen-portrait {
    &:before {
      font-size: 2.5vw;
    }
  }
}

.G-home,
.G-futu {
  font-family: $Aclonica-Regular;
  text-transform: uppercase;

}

[class*="icon"] {
  cursor: pointer;
}

.G-yoga {
  font-family: 'Questrial', sans-serif;
  text-transform: uppercase;

}

.G-live {
  font-family: 'Questrial', sans-serif;
  text-transform: lowercase;


}


.G-yellowText {
  color: $Contrast-colors-dark;
  font-family: $KronaOne-Regular;
  font-weight: 400;
}





