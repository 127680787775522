@import "src/assets/style";

.P-HomePageMusic {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns:repeat(5, 1fr);
  align-items: end;
  gap: 2vw;
  @include screen-portrait {
    gap: 6vw;
    grid-template-columns:repeat(2, 1fr);

  }

  .P-music {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: .5vw;
    box-sizing: border-box;
    border-bottom: .2vw solid $Contrast_colors-dark;
    padding-bottom: 1vw;
    border-radius: .5vw;
    @include screen-portrait {
      padding-bottom: 3vw;
      margin-top: 1.5vw;
      border-radius: 1.5vw;
      border-width: .6vw;
    }

    &:first-child {
      grid-area: 1 / 1 / 3 / 3;
      max-width: 100%;
    }
  }


  .P-audioCircle {

    .P-circle {
      width: 50%;
      aspect-ratio: 1;
      border-radius: 50%;
      box-shadow: 0px 0px .7vw $secondary-color-purple-light;
      margin: .5vw;
      margin-left: 0;
      position: relative;
      @include screen-portrait {
        margin: 1.5vw;
        margin-left: 0;
        box-shadow: 0px 0px 2.7vw $secondary-color-purple-light;

      }

      .P-delete {
        display: none;
        position: absolute;
        background: $error;
        padding: .5vw;
        color: $white;
        border-radius: 50%;
        @include screen-portrait {
          padding: 1.5vw;

        }
      }
    }

    &:hover {
      .P-circle {
        box-shadow: 0px 0px .7vw $white;
        @include screen-portrait {
          box-shadow: 0px 0px 2.7vw $white;

        }

        .P-delete {
          display: block;
        }
      }
    }

    .P-nameContainer {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }

    .P-text {
      display: flex;
      color: $white;
      width: fit-content;
      font-size: 1vw;
      @include screen-portrait {
        font-size: 4vw;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      &.yellow {
        color: $Contrast-colors-dark;
        font-size: 1.2vw;
        @include screen-portrait {
          font-size: 5.6vw;
        }
      }
    }
  }
}


.P-homeEachMusicPage {

}