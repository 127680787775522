@import "src/assets/style";


.G-form-image-upload {
  position: relative;
  width: 60%;
  aspect-ratio: 1;


  .G-image-name-container {
    position: relative;


    .icon-cross {
      position: absolute;
      top: 2vw;
      right: 2vw;
      @include screen-portrait {
        top: 6vw;
        right: 6vw;
      }
    }
  }

  .G-input-image-upload {
    display: none;
  }

  .G-label-image-upload {
    cursor: pointer;
    margin-top: .5vw;
    height: 100%;
    @include screen-portrait {
      margin-top: 1.5vw;
    }

    .G-file-image-container {
      cursor: auto;

      .icon {
        cursor: pointer;

      }
    }

    img {
      object-fit: cover;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      //padding: 0 1vw;
      height: 100%;
      background-color: $secondary-color-purple;
      border: .2vw dashed $secondary-color-purple-light;
      overflow: hidden;
      border-radius: 3vw;
      color: $secondary-color-purple-light;
      @include screen-portrait {
        border-radius: 9vw;
        border-width: .6vw;
      }

      p {
        text-align: center;
      }

      .icon {
        background-color: transparent;
        font-size: 100%;
      }

      .icon-mdi_user {
        font-size: 20vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @include screen-portrait {
          font-size: 50vw;
        }
      }
    }
  }

  .drag-image-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

}