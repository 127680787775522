@import "src/assets/style";

.P-eachMeditationDayAdminPage {

  .P-choiceBannerContainer {
    display: flex;

    .P-choiceBanner {
      //width: 20%;
      border-radius: 24px;
      overflow: hidden;
      @include screen-portrait {
        width: 100%;
      }
    }
  }
}