@import "src/assets/style";

.P-buttonLoader {

  min-width: 20vw;
  min-height: 3vw;
  border-radius: 2vw;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1vw;
  color: $white;
  border: 0.2vw solid;
  display: flex;
  justify-content: center;
  align-items: center;
  @include screen-portrait {
    min-width: 100%;
    min-height: 9vw;
    border-radius: 6vw;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3vw;
    border: 0.4vw solid;

  }

  .G-loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .G-loader {

      left: 50%;
      font-size: 0.3vw;
      transform: translateZ(0) translateX(-50%) translateY(50%);
    }
  }

  &.P-primaryBTN {
    background-color: $secondary-color-purple-light;
    border-color: $secondary-color-purple-light;
  }

  &.P-secondaryBTN {
    border-color: $secondary-color-purple-light;
    background-color: $secondary-color-purple;
  }

  &.disabled,
  &:hover {
    background: $Contrast_colors-dark !important;
    color: $secondary-color-purple !important;
    border: .2vw solid $Contrast_colors-dark !important;
    @include screen-portrait {
      border: .6vw solid $Contrast_colors-dark !important;

    }
  }
}

