@import "src/assets/style";

.P-MyMusicPage {
  height: calc(100% - 3vw);

  position: relative;
  z-index: 0;
  padding-top: 5.1vw;
  width: 100%;
  @include screen-portrait {
    padding-top: 17.8vw;
    min-height: calc(100% - 23vw);

  }

  &:before {
    content: "";
    background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/exclusivePage/background.webp");
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $secondary-color-purple;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    @include screen-portrait {
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/exclusivePage/background.webp");

    }

  }

  .G-container {
    position: relative;
  }

  .P-backArrowContainer {
    margin: 2.5vw 0;
    display: block;
  }

  .P-pageContent {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include screen-portrait {
      flex-direction: column;
      justify-content: flex-start;
    }

    .P-playersSection {
      width: 100%;
      overflow-x: auto;
      height: calc(100% - 20vh);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2vw;
      column-gap: 5vw !important;

      .P-player {
        width: 33%;
        @include screen-portrait {
          width: 100%;
        }
      }

      @include screen-portrait {
        width: 100%;
        height: calc(100% - 30vh);

      }

      &::-webkit-scrollbar-thumb {
        background-color: $Contrast_colors-dark;
      }
    }
  }

  .P-actionsSection {
    width: fit-content;
    display: flex;
    flex-direction: column;
    @include screen-portrait {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 7vh;
    }
  }

  .P-exclusiveButton {
    text-transform: uppercase;
    color: $white;
    font-size: 1.2vw;
    font-weight: bold;
    padding: .5vw .9vw .5vw 1.5vw;
    background-color: $main-color;
    width: 16vw;
    text-align: center;
    border-radius: 3vw;
    margin-bottom: 1.3vw;

    &.yellow {
      background-color: $Contrast-colors-light;
      color: $secondary-color-purple;
    }

    @include screen-portrait {
      font-size: 3.5vw;
      padding: 2vw 2vw 2vw 4vw;
      border-radius: 6vw;
      margin-bottom: 4.1vw;
      width: 44vw;

    }
  }
}