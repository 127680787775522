//@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');


// FONT FAMILY NAME AS VAR
// Aclonica


$Aclonica-Regular: 'Aclonica-Regular';
$Fofbb-Regular: 'fofbb_reg';


$font-path-Aclonica: '../../assets/fonts/Aclonica/';
$font-path-Fofbb: '../../assets/fonts/Fofbb/';
$list-Aclonica: [$Aclonica-Regular];
$list-Fofbb: [$Fofbb-Regular];

@each $name in $list-Aclonica {
  $src: $font-path-Aclonica + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
  }
}@each $name in $list-Fofbb {
  $src: $font-path-Fofbb + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
  }
}


// Fira_code armenian
$FiraCode-Bold: 'FiraCode-Bold';
$FiraCode-Light: 'FiraCode-Light';
$FiraCode-Medium: 'FiraCode-Medium';
$FiraCode-Regular: 'FiraCode-Regular';
$FiraCode-SemiBold: 'FiraCode-SemiBold';
$FiraCode-VariableFont_wght: 'FiraCode-VariableFont_wght';


$font-path-Fira_coden: '../../assets/fonts/Fira_Code/';
$list-Fira_code: [$FiraCode-Bold,$FiraCode-Light,$FiraCode-Medium,$FiraCode-Regular,$FiraCode-SemiBold,$FiraCode-VariableFont_wght];

@each $name in $list-Fira_code {
  $src: $font-path-Fira_coden + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
    font-display: swap;

  }
}


// Krona_One armenian
$KronaOne-Regular: 'KronaOne-Regular';


$font-path-Krona_One: '../../assets/fonts/Krona_One/';
$list-Krona_One: [$KronaOne-Regular];

@each $name in $list-Krona_One {
  $src: $font-path-Krona_One + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
    font-display: swap;

  }
}


// Poppins armenian
$Poppins-Black: 'Poppins-Black';
$Poppins-BlackItalic: 'Poppins-BlackItalic';
$Poppins-Bold: 'Poppins-Bold';
$Poppins-BoldItalic: 'Poppins-BoldItalic';
$Poppins-ExtraBold: 'Poppins-ExtraBold';
$Poppins-ExtraBoldItalic: 'Poppins-ExtraBoldItalic';
$Poppins-ExtraLight: 'Poppins-ExtraLight';
$Poppins-ExtraLightItalic: 'Poppins-ExtraLightItalic';
$Poppins-Italic: 'Poppins-Italic';
$Poppins-Light: 'Poppins-Light';
$Poppins-LightItalic: 'Poppins-LightItalic';
$Poppins-Medium: 'Poppins-Medium';
$Poppins-MediumItalic: 'Poppins-MediumItalic';
$Poppins-Regular: 'Poppins-Regular';
$Poppins-SemiBold: 'Poppins-SemiBold';
$Poppins-SemiBoldItalic: 'Poppins-SemiBoldItalic';
$Poppins-Thin: 'Poppins-Thin';
$Poppins-ThinItalic: 'Poppins-ThinItalic';


$font-path-Poppins: '../../assets/fonts/Poppins/';
$list-Poppins: [$Poppins-Black,$Poppins-BlackItalic,$Poppins-Bold,$Poppins-BoldItalic,$Poppins-ExtraBold,$Poppins-ExtraBoldItalic,
$Poppins-ExtraLight,$Poppins-ExtraLightItalic,$Poppins-Italic,$Poppins-Light,$Poppins-LightItalic,$Poppins-Medium,$Poppins-MediumItalic,
$Poppins-Regular,$Poppins-SemiBold,$Poppins-SemiBoldItalic,$Poppins-Thin,$Poppins-ThinItalic];

@each $name in $list-Poppins {
  $src: $font-path-Poppins + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
    font-display: swap;

  }
}


// Questrial armenian
$Questrial-Regular: 'Questrial-Regular';


$font-path-Questrial: '../../assets/fonts/Questrial/';
$list-Questrial: [$Questrial-Regular];

@each $name in $list-Fira_code {
  $src: $font-path-Fira_coden + $name;

  @font-face {
    src: url($src + '.ttf') format('truetype');
    font-family: $name;
    font-display: swap;

  }
}
