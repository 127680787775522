// colors

$main-color: #B429F9;
$Contrast_colors-dark: #C6F91F;
$Contrast_colors-light: #DDF45B;
$secondary-color-purple: #5A108F;
$secondary-color-purple-light: #B429F9;
$secondary-color-pink-dark: #DC97FF;
$secondary-color-pink-light: #ECBCFD;
$secondary-color-red: #660708;
$secondary-color-blue: #48CAE4;
$error: #ff1818;
$error-light: #ff3030;
$black: #000000;

$white: #ffffff;


// Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-33: 33px;
$font-size-34: 34px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-46: 46px;
$font-size-48: 48px;
$font-size-63: 63px;

//page withs

$max-width-1300: 1300px;
$max-width-1200: 1200px;
$max-width-991: 991px;
$max-width-767: 767px;
$max-width-576: 576px;

// transitions
$transition: 0.3s;
