@import "src/assets/style";

.P-audioPlayerCircle {
  width: fit-content;
  color: $white;
  //text-transform: capitalize;
  transition: all .3s ease-in-out;
  position: relative;
  margin-bottom: 3vw;
  @include screen-portrait {
    margin-bottom: 3vw;
  }

  .moving {
    min-height: 2.5vw;
    overflow: hidden;
    padding-right: 3vw;
    left: 0;
    display: flex;
    align-items: center;

    --mask: linear-gradient(to right,
            rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 90%
    ) 100% 100% / 100% 100% repeat-y;
    mask: var(--mask);
    @include screen-portrait {
      min-height: 5vw;
      --mask: linear-gradient(to right,
              rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%
      ) 100% 100% / 100% 100% repeat-y;
    }

    .P-text {
      //transform: translateX(calc(100%));
      opacity: 0;
      animation: moving 10s linear infinite;
      -webkit-animation: moving 10s linear infinite;

      &:nth-child(2) {
        animation-delay: -5s;
        -webkit-animation-delay: -5s;
        position: absolute;
      }

      display: block;

      &:after {
        //content: attr(data-name);
        //opacity: 0;
        //animation: moving 10s linear infinite;
        //display: block;
      }
    }

    /*&:hover {
      mask: none;

      .P-text {
        white-space: normal;
        width: 100%;
        animation: none;


      }
    }*/
  }

  &.open {
    //margin-right: 4vw;
    //z-index: 99999;
  }

  .P-audioPopUp.open {
    z-index: -2;
  }

  * {
    transition: all .3s ease-in-out;
    color: $white;

  }

  &.drag:active {
    cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;

    cursor: grabbing !important;
  }

  &.errorClass {
    animation: shake .5s ease-in-out forwards;

    .P-circle {
      box-shadow: 0px 0px .7vw #FF2222 !important;

    }

    .icon-play {
      text-shadow: 0px 0px .7vw #FF2222 !important;
    }
  }

  .P-text {
    white-space: nowrap;
    width: fit-content;


  }

  .G-flex:has(.moving):after {
    //content: "...";
    position: absolute;
    right: 0vw;
  }


  /* .G-flex:has(.moving:hover):after {
     content: none;
     position: absolute;
     right: 0vw;
   }*/

  .P-nameContainer {
    padding-left: 3vw;
    width: calc(100% + 2vw);
    position: absolute;
    right: -2vw;
    text-align: right;
    z-index: 2;


  }

  .P-fit {
    position: absolute;
    color: $Contrast_colors-dark;
    left: 0;
    //bottom: 14vw;
    margin-top: -.5vw;
    font-size: 1.2vw;
    @include screen-portrait {
      //bottom: 30vw;
      font-size: 3.5vw;
      margin-top: -1vw;
    }

  }

  .P-circle {
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0px 0px .7vw #FFFFFF;
    position: relative;


    &.glitch {
      &:hover {
        &:before {
          animation: glitcRed 1.7s ease-in-out forwards;

        }

        &:after {
          animation: glitcBlue 2s ease-in-out forwards;

        }
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: inherit;
        mask-image: radial-gradient(#000 99%, transparent 100%);

        mask-repeat: no-repeat;
        mask-position: left;

      }


      &:before {
        filter: hue-rotate(90deg);

      }

      &:after {
        filter: hue-rotate(290deg);

      }
    }

  }

  &.L {
    //font-size: 1.2vw;
    font-family: $KronaOne-Regular;
    white-space: pre;

    .icon-play {
      //position: absolute;
    }

    .P-text {
      //width: 100%;
      text-align: center;
      //position: absolute;
      //left: 2.5vw;
      //right: 0;
      font-size: 1.2vw;
      //transform: translateX(-50%);
      //text-align: center;
    }

    .P-circle {
      width: 7vw;

    }

    @include screen-portrait {
      font-size: 4vw;
      .P-text {
        font-size: 3vw;

      }
      .P-circle {
        width: 22.4vw;
      }
    }
  }

  &.M {
    font-size: 1vw;
    font-family: $Poppins-Regular;
    white-space: pre;

    .P-circle {
      width: 5vw;
    }

    @include screen-portrait {
      font-size: 2.3vw;
      .P-circle {
        width: 16.3vw;
      }
    }
  }

  .icon-pause,
  .icon-play {
    color: $black;
    text-shadow: 0px 0px .7vw #FFFFFF;
    font-size: 2.5vw;
    margin-top: -1.2vw;
    margin-left: .8vw;
    margin-right: .2vw;
    z-index: 4;
    @include screen-portrait {
      font-size: 6.6vw;
      margin-top: -4.2vw;
      margin-left: .8vw;
    }
  }


  &:hover .icon-play {
    animation: shake .5s ease-in-out forwards;

  }

  .P-player {
    position: absolute;
    margin-bottom: -10vw;
  }

}


@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);

  }
  30% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);

  }
  90% {
    transform: rotate(10deg);

  }
  100% {
    transform: rotate(0deg);

  }
}


@keyframes glitcRed {
  0% {
    opacity: .5;
    transform: translateX(-5%);
  }
  4% {
    opacity: .5;
    transform: translateX(-5%);
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: .5;
    transform: translateX(5%);

  }
  14% {
    opacity: .5;
    transform: translateX(5%);
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: .51;
    transform: translate(-5%, 5%);

  }
  24% {
    opacity: .5;
    transform: translate(-5%, 5%);
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: .5;
    transform: translateX(5%);
  }
  34% {
    opacity: .5;
    transform: translateX(5%);
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: .5;
    transform: translate(-5%, -5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  44% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: .5;
    transform: translate(5%, 5%);
    mask-size: 100% 50%;
    -webkit-mask-size: 100% 50%;
  }
  54% {
    opacity: .5;
    transform: translate(5%, 5%);
  }
  55% {
    opacity: 0;
  }


}

@keyframes glitcBlue {
  0% {
    opacity: .5;
    transform: translateX(5%);
    mask-size: 100% 50%;
    -webkit-mask-size: 100% 50%;
  }
  4% {
    opacity: .5;
    transform: translateX(5%);
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: .5;
    transform: translateX(-5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  14% {
    opacity: .5;
    transform: translateX(-5%);
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: .5;
    transform: translate(5%, -5%);
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }
  24% {
    opacity: .5;
    transform: translate(5%, -5%);
    mask-position: bottom;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: .5;
    transform: translate(-5%, 5%);
    mask-size: 100% 10%;
    -webkit-mask-size: 100% 10%;
  }
  34% {
    opacity: .5;
    transform: translate(-5%);
    mask-size: 10% 100%;
    -webkit-mask-size: 10% 100%;
    mask-position: center;

  }
  35% {
    opacity: 0;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }
  40% {
    opacity: .5;
    transform: translateX(5%);
  }
  44% {
    opacity: .5;
    transform: translateX(5%);
  }
  45% {
    opacity: 0;
  }

  50% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  54% {
    opacity: .5;
    transform: translate(-5%, -5%);
  }
  55% {
    opacity: 0;
  }


}


@keyframes moving {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  0.1% {
    opacity: 1;
  }
  99.8% {
    opacity: 1;
  }
  99.9% {
    transform: translateX(calc(-100% - 35vw));
    opacity: 0;

  }
  100% {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

}