@import "src/assets/style";

.P-authPopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2.5vw;
  border: 0.2vw solid $Contrast_colors-dark;
  background: $secondary-color-purple;
  padding: 2.5vw 3vw;
  @include screen-portrait {
    border-radius: 7.5vw;
    border: 0.6vw solid $Contrast_colors-dark;
    background: $secondary-color-purple;
    padding: 7.5vw 9vw;
    max-width: 100%;
  }

  span.icon-cross {
    position: absolute;
    top: -1.7vw;
    right: 2vw;
    color: $secondary-color-purple;
    background-color: $Contrast_colors-dark;
    padding: .7vw;
    border-radius: 50%;
    @include screen-portrait {
      top: -4.7vw;
      right: 6vw;
      padding: 1.7vw;
      font-size: 6vw;
    }
  }

  .P-popUpTitle {
    color: $secondary-color-purple-light;
    width: fit-content;
    margin: 2.2vw auto;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3.2vw;
    @include screen-portrait {
      margin: 6.2vw auto;
      font-size: 9.2vw;
    }
  }

}