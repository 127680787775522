@import "src/assets/style";

.swal-html {
  .P-loginButton {
    background: none;
    color: $white;
    text-decoration: underline;
    font-weight: 900;
    font-size: 2vw;
    @include screen-portrait {
      font-size: 6vw;
    }
  }
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-deny:hover {
  background: $Contrast_colors-light !important;
}

.P-meditationPage {
  .P-player {
    position: fixed;
  }

  .P-backArrowContainer {
    position: relative;
    margin-top: 1vw;
    display: block;
    @include screen-portrait {
      margin-top: 4vw;
    }

    img {
      filter: drop-shadow(0px 0.2vw 0.1vw #5A108F);
    }

    span {
      color: $Contrast_colors-light;
      text-shadow: 0px 0.1vw 0.1vw #5A108F;
    }
  }

  .P-actionsContainer {
    .P-backArrowContainer {
      span {
        color: $main-color;
      }
    }
  }

  .P-audioPopUp .P-textContainer .P-musicTextContainer .P-textWrapper .P-musicText {
    white-space: normal;
  }

  .P-wavePlayer [class*=icon-],
  .P-audioPopUp .P-icons {
    display: none;
  }

  .P-wavePlayer {
    margin-top: -5vw;

    .P-audioWavePlayer {
      margin-left: 0;
    }
  }

  .P-choiceBanner {
    width: 11.5vw;
    cursor: pointer;
    aspect-ratio: 1;
    border-radius: 1vw;
    border: .1vw solid $Contrast_colors-dark;
    background-color: rgba(180, 41, 249, 0.23);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: relative;
    z-index: 99;
    height: auto;
    max-height: 100%;

    .P-downloadButton {
      position: absolute;
      right: -30%;
      bottom: 0;
      transform: translateX(100%);
      background: none;
      color: $white;
      border: .2vw solid $white;
      border-radius: 4vw;
      padding: 1vw 2vw;
      white-space: nowrap;
      text-transform: uppercase;
      @include screen-portrait {
        bottom: -15vw;
        left: 0;
        right: auto;
        transform: translateX(0);
      }
    }

    &.fixed {
      position: fixed;
      bottom: 10vw;
      @include screen-portrait {
        bottom: 40vw;
        left: 10vw;
      }
    }

    //for comment
    display: flex;
    align-items: center;
    justify-content: center;
    //overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }

    //________________________
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border-radius: 1vw;
      border: .1vw solid $Contrast_colors-dark;
      background-color: rgba(180, 41, 249, 0.23);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      z-index: -2;
      mask-image: radial-gradient(#000 99%, transparent 100%);

      mask-repeat: no-repeat;
      mask-position: left;
      @include screen-portrait {
        width: 20vw;
        border: .5vw solid $Contrast_colors-dark;
        border-radius: 8.6vw;
      }

    }


    &:before {
      filter: hue-rotate(90deg);

    }

    &:after {
      filter: hue-rotate(290deg);

    }

    @include screen-portrait {
      width: 25vw;
      border: .5vw solid $Contrast_colors-dark;
      border-radius: 2.6vw;

    }

    [class*=icon] {
      color: $Contrast_colors-light;
      font-size: 3vw;
      position: absolute;
      right: -2vw;
      bottom: 1vw;
      @include screen-portrait {
        font-size: 9vw;
        right: -6vw;
      }
    }
  }

  .P-meditationContainer {
    position: relative;
    z-index: 0;
    padding-top: 4.1vw;
    width: 100%;
    //overflow-x: hidden;
    height: 100%;

    &:before {
      content: "";
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/BACKGROUND.webp");
      height: auto;
      background-size: 100%;
      background-position: top center;
      background-repeat: no-repeat;
      background-color: $secondary-color-purple;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100vw;

    }

    &:after {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100vw;
      content: "";
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/PLANTS.webp");
      background-size: cover;
      background-position: center 35%;
      background-repeat: no-repeat;
      pointer-events: none;
    }

    @include screen-portrait {
      padding-top: 15vw;
      overflow: hidden;
      height: calc(100%);

      &:before {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/BACKGROUNDmobile.webp");

      }
      &:after {
        background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/yogaPage/PLANTSmobile.webp");
      }
    }


    .P-day {
      min-height: 90%;
      padding: 2vw 0;
      position: relative;
      @include screen-portrait {
        padding: 6vw 0;
      }

      &:not(:first-child) {
        border-top: .2vw solid $white;
      }

      .P-dayTitle {
        color: $Contrast_colors-light;
        font-family: Questrial, sans-serif;
        letter-spacing: 1.1px;
        padding: 0 0 1vw;
        text-align: center;
        font-size: 3.5vw;
        text-transform: uppercase;
        @include screen-portrait {
          font-size: 9vw;
        }
      }

      .P-audios {

        position: absolute;
        z-index: 1;
        @include screen-portrait {
          position: relative;
        }

        .P-audioTitle {
          font-size: 2vw;
          font-family: KronaOne-Regular;
          color: $Contrast_colors-light;
          text-align: center;
          text-transform: uppercase;
          text-shadow: 0px 0.1vw 0.1vw #5A108F;
          @include screen-portrait {
            font-size: 6vw;
          }
        }

        .P-audiosContainer {
          max-height: 7.8vw;
          overflow: auto;
          width: 20vw;
          mask-image: linear-gradient(black 80%, transparent);
          @include screen-portrait {
            width: 100%;
            max-height: 19vh;
            mask-image: linear-gradient(black 50%, transparent);

          }

          .P-audio {
            padding: .2vw .8vw;
            border-radius: .4vw;
            cursor: pointer;
          }

          .P-audio:hover,
          .active {
            box-shadow: 0px 0px 50px 2px rgba(255, 255, 255, 0.2) inset;
          }

          &::-webkit-scrollbar {
            background: none;
          }
        }
      }
    }

    .P-selectedAudio {
      position: relative;

      @include screen-portrait {
        padding-top: 4vw;
      }

      .P-choiceTitle {
        font-size: 2.7vw;
        color: $white;
        text-align: center;
        padding: 0 0 1vw;
        @include screen-portrait {
          font-size: 8vw;
        }
      }

      .P-descriptionText {
        color: $white;
        text-align: center;
        font-family: KronaOne-Regular;
        font-size: 1.46vw !important;
        font-weight: bold !important;
        text-shadow: 0px 0.1vw 0.1vw #5A108F;
        @include screen-portrait {
          font-size: 4.5vw !important;
        }
      }

      .P-backButton {
        position: absolute;
        background: none;
        width: 10vw;
        left: 0;
        top: 0;
        transform: translateY(-50%);
        height: fit-content;
        @include screen-portrait {
          width: 30vw;
        }
      }

      .P-choiceBanners {
        display: flex;
        justify-content: center;


        //padding: 1vw 0;
      }

      .P-choiceBannerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1vw 1vw;
        color: $Contrast_colors-light;
        @include screen-portrait {
          padding: 0 1vw 4vw;
        }
      }

    }


    .slick-slider {
      height: calc(100% - 4vw);
      display: flex;
      flex-direction: column;
      z-index: 1;
      @include screen-portrait {
        height: calc(100% - 14vw);
      }

      .slick-list {
        flex-basis: 100%;
        height: auto !important;

        .slick-slide > div,
        .slick-slide > div > div {
          height: 100%;
          width: 100%;

        }

        .slick-slide > div > div {
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .slick-track {
          height: 100%;
        }

        .slick-slide {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }

      .slick-dots {
        position: relative;
        bottom: unset;
        margin-bottom: 2vw;
        overflow: auto;
        display: flex !important;
        padding: 0 3vw;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        //border-radius: 2vw;
        box-shadow: 0px 75px 28px -84px rgba(255, 255, 250, 0.6) inset;
        --mask: linear-gradient(to right,
                rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 5%,
                rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 95%
        ) 100% 100% / 100% 100% repeat-x;
        mask: var(--mask);
        color: #FFFFFF;
        font-size: 1.2vw !important;
        font-weight: bold !important;
        text-shadow: 0px 0.1vw 0.1vw #5A108F;
        @include screen-portrait {
          font-size: 4vw;
        }

        li {
          width: fit-content;
          height: auto;
          margin: 0px ;
          padding: 1vw 1vw 0;
          cursor: pointer;
          max-width: 30%;

          font-size: 1vw;

          &:first-child{
            margin-inline-start: auto;
          }
          &:last-child{margin-inline-end: auto;}
          @include screen-portrait {
            font-size: 4vw;
            padding: 3vw 3vw 0;
          }

          &.slick-active:after {
            content: "";
            display: block;
            width: 2vw;
            height: 2vw;
            border-radius: .4vw;
            /* rotate: 45deg; */
            transform: translate(-50%, -70%) rotate(45deg);
            position: absolute;
            top: 0;
            left: 50%;
            box-shadow: 0px 0px 18px -4px rgba(255, 255, 250, 0.6);
            z-index: 2;
          }

          .P-tab {
            width: 5vw;
            @include screen-portrait {
              width: 16.3vw;
            }

            .P-text {
              //transform: translateX(calc(100%));
              opacity: 0;
              animation: moving 10s linear infinite;
              -webkit-animation: moving 10s linear infinite;

              &:nth-child(2) {
                animation-delay: -5s;
                -webkit-animation-delay: -5s;
                position: absolute;
              }

              display: block;

              &:after {
                //content: attr(data-name);
                //opacity: 0;
                //animation: moving 10s linear infinite;
                //display: block;
              }
            }

            .P-text {
              white-space: nowrap;
              width: fit-content;
            }

            .moving {
              min-height: 2.5vw;
              overflow: hidden;
              padding-right: 3vw;
              left: 0;
              display: flex;
              align-items: center;

              --mask: linear-gradient(to right,
                      rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 90%
              ) 100% 100% / 100% 100% repeat-y;
              mask: var(--mask);
              @include screen-portrait {
                min-height: 5vw;
                --mask: linear-gradient(to right,
                        rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%
                ) 100% 100% / 100% 100% repeat-y;
              }
            }

            p + p {
              max-width: 100% !important;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .P-meditationTab {
            aspect-ratio: 1;
            width: 5vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto .5vw;
            border-radius: 50%;
            box-shadow: 0px 0px 18px -4px rgba(255, 255, 250, 0.6);
            overflow: hidden;
            @include screen-portrait {
              width: 16.3vw;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;

            }
          }
        }
      }
    }
  }
}


@keyframes moving {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  0.1% {
    opacity: 1;
  }
  99.8% {
    opacity: 1;
  }
  99.9% {
    transform: translateX(calc(-100% - 25vw));
    opacity: 0;

  }
  100% {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

}