@import "src/assets/style";

.P-SampleMusic {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2vw;
  margin-top: 2vw;
  @include screen-portrait {
    gap: 6vw;
    margin-top: 6vw;
  }

  .P-music {

    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: .5vw;
    box-sizing: border-box;
    padding: 1vw 2vw;
    border-radius: .5vw;
    position: relative;
    @include screen-portrait {
      padding: 4vw 10vw 4vw 3vw;
      margin-top: 1.5vw;
      border-radius: 1.5vw;
      border-width: .6vw;
      width: 100%;
    }

    &:first-child {
      max-width: 100%;
    }

    .P-icon {
      display: none;
      position: absolute;
      padding: .5vw;
      color: $white;
      border-radius: 50%;
      right: 0vw;
      @include screen-portrait {
        padding: 1.5vw;

      }

      &.icon-pen {
        background: $secondary-color-purple-light;
        top: 0;

      }

      &.icon-bin {
        background: $error;
        bottom: 0;
      }
    }

    &:hover {
      .P-icon {
        display: block;
      }
    }
  }


  .P-nameContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .P-text {
    display: flex;
    color: $white;
    width: fit-content;
    font-size: 1vw;
    @include screen-portrait {
      font-size: 4vw;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    &.yellow {
      color: $Contrast-colors-dark;
      font-size: 1.2vw;
      @include screen-portrait {
        font-size: 5.6vw;
      }
    }
  }
}

.P-SampleMusicPage {
  .P-musicContainer {
    margin-bottom: 2vw;
  }
}
