.P-click-outsideBlock {
  position: fixed;
  z-index: 999999999;
  width: auto;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(90, 16, 143, 0.3);


}