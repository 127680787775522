.P-forgotPassword{
  .P-buttons{
    display: flex;
    justify-content: space-between;
//gap: 10px;
    .P-buttonLoader{
      min-width: 10vw;
      width: 48%;
    }
  }
}