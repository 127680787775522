@import "src/assets/style";

.P-header {
  height: 4.1vw;
  width: 100vw;
  background-color: rgba(90, 16, 143, 0.83);
  //backdrop-filter: opacity(83%);
  //opacity: 83%;
  position: fixed;
  z-index: 3;
  box-shadow: .2vw .2vw .5vw 0px rgba(50, 5, 82, 0.30);

  @include screen-portrait {
    height: 16.3vw;
    box-shadow: 1.2vw 1.2vw 1.5vw 0px rgba(50, 5, 82, 0.30);
    .P-profile {
      margin-left: auto;
      margin-right: 10vw;
    }
  }

  .P-navigationContainer {
    padding-top: .6vw;
    padding-bottom: .6vw;
    max-height: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    @include screen-portrait {
      padding-top: 2.6vw;
      padding-bottom: 2.6vw;
    }

    .P-logo {
      max-height: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 1/1.3;
      width: fit-content;

      img {
        object-fit: contain;
        aspect-ratio: 1/1.3;
      }
    }

    .P-donateButton {
      background: none;
      margin: 0 5.1vw;
      text-shadow: 0px 0px 4px #FFFFFF;
      font-size: 1.7vw;
      line-height: 130%;
      font-family: $Poppins-SemiBold;
      text-transform: uppercase;
      color: $Contrast_colors-dark;
      transition: all 0.5s ease-in-out;
      @include screen-portrait {
        margin: 0 3vw 0 3vw;
        font-size: 5.6vw;

      }

      &.comingSoon:before {
        font-size: 0.9vw;
        transform: scale(1.3, 2) !important;
        color: $Contrast_colors-dark;
        @include screen-portrait {
          font-size: 2.7vw;
        }
      }

      &:hover {
        transform: scale(1, .5)

      }
    }

    .P-socialIconsSection {
      margin-left: auto;
      margin-right: 3.1vw;


      @include screen-portrait {
        display: none;
      }
    }

    .P-authorizationActions {
      display: flex;
      gap: .4vw;
      align-items: center;
      @include screen-portrait {
        margin-right: 2.5vw;
        margin-left: auto;
        gap: 2.5vw;
      }
    }

    .P-soundIconContainer {
      position: absolute;
      color: $Contrast_colors-dark;
      cursor: pointer;
      right: .5vw;
      width: 1.5vw;
      font-size: 1.5vw;
      display: flex;
      align-items: center;
      min-width: 1.5vw;
      margin-left: .55vw;


      &:hover {
        color: $Contrast_colors-light;
      }

      @include screen-portrait {
        position: relative;
        right: auto;
        width: 5.34vw;
        min-width: 5.34vw;
        font-size: 5.34vw;
      }
    }
  }
}

.P-regulationTitle{
  color: #B429F9;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2.2vw auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3.2vw;
  @include screen-portrait{
    margin: 6.2vw auto;
    font-size: 9.2vw;
  }
}

.P-regulations{
  color: #B429F9;
font-size: 2vw;
  white-space: pre-line;
  max-height: 60vh;
  overflow-y: auto;
  @include screen-portrait{
    font-size: 6vw;
  }
}