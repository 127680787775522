@import "src/assets/style";

.P-wavePlayer {
  display: flex;

  [class*="icon-"] {
    margin: 0 .5vw;
    font-size: 1.5vw;
    z-index: 9999999;
    color: $secondary-color-purple;
    position: absolute;
    top: -0vw;
    right: 0vw;

    @include screen-portrait {
      font-size: 12vw;
      position: absolute;
      top: -10vw;
      right: 0vw;
    }
  }

  .icon-previous {
    right: 2vw;
    @include screen-portrait {
      right: 14vw;

    }
  }

  .P-audioWavePlayer {
    width: 100%;
    padding: 0 1vw;
    position: relative;
    display: block;
    z-index: 99;
    margin-right: 3vw;
    cursor: pointer;
    margin-left: -23vw;
    z-index: 9999999999999;
    display: flex;
    align-items: center;


    @include screen-portrait {
      width: 73vw !important;
      padding: 0 6vw;
      margin: 0 12vw;
      position: fixed;
      left: 1.5vw;
      height: 6vw !important;
      //margin-top: -5vw;


    }

    input[type="range"] {
      z-index: 999;
      position: absolute;
      -webkit-appearance: none;
      background: none;
      accent-color: $secondary-color-purple-light;
      height: 5vw;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      cursor: pointer;
      @include screen-portrait {
        height: 15vw;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;

        height: 1vw;
        width: 1vw;
        border-radius: 50%;
        background: none;
        @include screen-portrait {
          width: 15vw;
          height: 15vw;
        }
      }

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;

        height: 5vw;
      }

      /* ... */
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5vw;
      cursor: pointer;
    }

    //input[type="range"]::before {
    //  /* ... */
    //  background: #f50;
    //  width: var(--range-progress);
    //  /* ... */
    //}


    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/disactive.png");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;

    }

    .P-after {
      content: "";
      width: 100%;

      height: 100%;
      position: absolute;
      left: 0;
      top: -.1px;
      background-image: url("https://futustatics.s3.eu-central-1.amazonaws.com/images/Player/active.png");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;

      mask-image: radial-gradient(#000 99%, transparent 100%);
      mask-repeat: no-repeat;
      mask-position: left;
      mask-size: inherit;
      transition: none;
    }

    .P-currentTime {
      position: absolute;
      left: -4vw;
      top: 55%;
      transform: translateY(-50%);
      color: $secondary-color-purple-light;
      font-weight: 600;
      font-family: $Poppins-Bold;
      cursor: auto;
      font-size: 1.2vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3vw;
    }


    .P-duration {
      position: absolute;
      right: -4vw;
      top: 55%;
      transform: translateY(-50%);
      color: $secondary-color-purple;
      font-weight: 600;
      font-family: $Poppins-Bold;
      cursor: auto;
      font-size: 1.2vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3vw;

    }

    @include screen-portrait {
      .P-currentTime,
      .P-duration {
        font-size: 3vw;
        width: 8vw;

      }
      .P-duration {
        right: -10vw;
      }
      .P-currentTime {
        left: -10vw;
      }
    }

    div {
      height: 3vw !important;
      @include screen-portrait {
        height: 6vw !important;
      }
    }
  }

}
