@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z4ixka');
  src:  url('fonts/icomoon.eot?z4ixka#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z4ixka') format('truetype'),
    url('fonts/icomoon.woff?z4ixka') format('woff'),
    url('fonts/icomoon.svg?z4ixka#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-locked:before {
  content: "\e915";
}
.icon-picture:before {
  content: "\e912";
}
.icon-pointer:before {
  content: "\e90f";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-shopping-cart:before {
  content: "\e913";
}
.icon-cart-plus:before {
  content: "\e914";
}
.icon-menu_open:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-mdi_user:before {
  content: "\e90b";
}
.icon-next:before {
  content: "\e909";
}
.icon-previous:before {
  content: "\e90a";
}
.icon-forward:before {
  content: "\e907";
}
.icon-backward:before {
  content: "\e908";
}
.icon-loop:before {
  content: "\e904";
}
.icon-repeat:before {
  content: "\e905";
}
.icon-pause:before {
  content: "\e903";
}
.icon-settings:before {
  content: "\e90c";
}
.icon-pen:before {
  content: "\e90d";
}
.icon-music:before {
  content: "\e90e";
}
.icon-mute:before {
  content: "\e906";
}
.icon-chat:before {
  content: "\e900";
}
.icon-sound:before {
  content: "\e901";
}
.icon-play:before {
  content: "\e902";
}
